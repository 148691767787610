<template>
    <el-select v-model="id" placeholder="请选择学期">
      <el-option
          v-for="item in options"
          :key="item.value"
          :label="item.label"
          :value="item.value"></el-option>
    </el-select>
</template>
<script>
export default {
  name: "gradeSelect",
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    require: {
      type: Boolean,
      default: true
    },
    semesterId:{
      type:[String,Number],
      default:""
    },
    type:{
      type:String,
      default:"all"
    }
  },
  data() {
    return {
      options: [],
    };
  },
  computed: {
    id: {
      get() {
        return this.value
      },
      set(value) {
        console.log("选择")
        this.$emit('input', value)
        this.$emit('change', value)
      }
    }
  },
  watch:{
    semesterId(){
      if(this.type === 'task'){
        this.createList()
      }
    }
  },
  mounted() {
    this.createList()
  },
  methods:{
    createList() {
      switch (this.type) {
        case 'all':
          this.options = [
            {label:"一年级",value:1},
            {label:"二年级",value:2},
            {label:"三年级",value:3},
            {label:"四年级",value:4},
            {label:"五年级",value:5},
            {label:"六年级",value:6}
          ]
          break
        case 'task':
          this.getTaskGrade()
          break
      }
    },
    getTaskGrade() {
      if(!this.semesterId){
        console.log("没有学期")
        return
      }
      this.$cloud.get('/task/grade/list',{semesterId:this.semesterId}).then(res=>{
        console.log('res',res)
        this.options = res
      })
    }
  }
};
</script>
<style scoped>

</style>
