<!--
 * ======================================
 * 说明： 教师端 - 活动
 * 作者： Silence
 * 文件： study.vue
 * 日期： 2023/8/3 0:32
 * 邮箱： 1106194754@qq.com
 * ======================================
-->
<template>
  <Scrollbar>
    <Title title="活动管理"/>
    <div class="m-4">
      <el-form :inline="true" :model="formInline" class="demo-form-inline" >
        <el-form-item label="选择学期">
          <semesterSelect v-model="formInline.semester_id" @onLoadFinish="handleLoad"/>
        </el-form-item>
      </el-form>
    </div>
    <div class="m-4">
      <el-form :inline="true" :model="formInline" class="demo-form-inline" >
        <el-form-item label="选择年级">
          <grade-select type="task" :semesterId="formInline.semester_id" v-model="formInline.grade" @change="handleLoad"/>
        </el-form-item>
      </el-form>
    </div>
    <div class="m-2">
      <el-form ref="form" :model="formData" label-width="80px">
          <el-form-item label="活动名称">
            <el-form label-width="150px">
              <el-form-item v-for="(item,index) in options" :key="item.key" :label="`第${index+1}次活动`">
                <el-input v-model="item.content" placeholder="请输入" maxlength="200" show-word-limit/>
              </el-form-item>
            </el-form>
          </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="handleSubmit">保存活动</el-button>
        </el-form-item>
      </el-form>
    </div>
  </Scrollbar>
</template>
<script type="text/javascript">
import GradeSelect from "@/pages/web/components/gradeSelect.vue";
import semesterSelect from "./components/semesterSelect";

export default {
  name: '',
  components: {
    semesterSelect,
    GradeSelect
  },
  inject: ["WEB"],
  data() {
    return {
      isDefault: true,  // 默认查询加载中的第一次
      semesterOptions: [],
      props: {
        multiple: true,
      },
      formInline: {
        semester_id: '',
        grade:'',
        block_id:'',
      },
      formData: {
        title: '',
        content: [],
        datetime: '',
        status: 0
      },
      options: [],
      data:[]
    }
  },
  async mounted() {
    // 加载年级
    await this.handleLoadGrade();
  },
  methods: {
    handleLoad() {
      this.options = []
      let menuInfo = this.WEB.getMenuInfo();
      this.formInline.block_id = menuInfo["block_id"];
      const data = {
        blockId:this.formInline.block_id,
        grade:this.formInline.grade,
        semesterId:this.formInline.semester_id
      }
      return this.$cloud.get("block/activities", data).then(res => {
        console.log("res", res)
        this.createOptions(res)
        this.handleLoadName()
      })
    },
    async handleLoadGrade(){
      await this.$cloud.get("grade/get/now").then(res=>{
        this.formInline.grade= res
        setTimeout(() =>{
          this.handleLoad()
        },500)
      })
    },
    createOptions(list) {
      list.forEach(v=>{
        console.log('v',v)
        if(v.subject_id){
          let item = {
            subjectId:v.subject_id,
            key:v.key,
            label:v.label,
            content:''
          }
          this.options.push(item)
        }else{
          if(v.children){
            this.createOptions(v.children)
          }
        }
      })
    },
    handleLoadName() {
      const data = {
        blockId:this.formInline.block_id,
        grade:this.formInline.grade,
        semesterId:this.formInline.semester_id
      }
      this.$cloud.get('activities/list',data).then(res =>{
        console.log("已保存的列表",res,this.options)
        this.options.forEach(x=>{
          let content = res.find(v=>{
            return v.key === x.key && parseInt(v.subject_id) === parseInt(x.subjectId)
          })
          console.log('content',content)
          if(content){
            x.content = content.content
          }
        })
      })
    },
    handleSubmit() {
      console.log("保存",this.options)
      let data = {
        blockId:this.formInline.block_id,
        grade:this.formInline.grade,
        semesterId:this.formInline.semester_id,
        list:this.options
      }
      this.$cloud.post('activities/save',data).then(() => {
        this.$message.success("保存成功")
      })
    }
  },
}
</script>
<style lang="scss">
.el-tabs--border-card > .el-tabs__content {
  padding: 0;
}
</style>
